

/* ------------------------------------------------------------------

1. Imported styles
2. Global styles
3. Button style
4. Sec title style
5. Header-style
6. Owl Slider style
7. page title style
8. latest-news style
9. feature style
10. google map Section style
11. contact Section style
12. Team Member styles
13. latest-gallery styles 
14. Checkout Area style
15. page_pagination style
16. footer style
17. about style
18. our-services style
19. recent-project style
20. call-to-action styles 
21. Parallax Style One
22. team Section style
24. service-single Section style
25. gallery Section
26. Side Bar Style
27. Blog Page Style
28. blog grid page style
29. feature-style-three
30. contact_us style

------------------------------------------------------------------- */


/*[font Variables]*/

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css");
/*[debugging.css]*/

/*@import url('header.css');*/
/* @import url('header_transparent.css');
@import url('slider.css'); */
/* @import url('page-css/about-page.css'); */
/* @import url('page-css/services-page-style.css'); */
/* @import url('page-css/gallery-style.css'); */
/* @import url('page-css/team-page-style.css'); */

/* @import url('page-css/faq-page-style.css'); */
/* @import url('page-css/blog-pages-style.css'); */
/* @import url('page-css/services-page.css'); */
/* @import url('page-css/project-detail.css');
@import url('page-css/contact-page-style.css');
@import url('page-css/footer.css');
@import url('hover.css'); */

/* @import url('flaticon.css'); */
html , body{
  a{
    text-decoration: none;
  }
  overflow-x: hidden;
}
@font-face {
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    src: url("http://netdna.bootstrapcdn.com/font-awesome/2.0/font/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("http://netdna.bootstrapcdn.com/font-awesome/2.0/font/fontawesome-webfont.woff") format("woff"), url("http://netdna.bootstrapcdn.com/font-awesome/2.0/font/fontawesome-webfont.ttf") format("truetype"), url("http://netdna.bootstrapcdn.com/font-awesome/2.0/font/fontawesome-webfont.svg#FontAwesome") format("svg");
}
:root {
  --color-primary: #ec0582;
  --color-secondary: #f4f5f6;
  --color-tertiary: #0d1013;
  --color-gray: #f6f6f6;
  --color-subtitle: #f9004d;
  --background-color-red: #ed1c24;
  --color-dark: #4a4a4a;
  --color-text:#4a4a4a;
  --color-black:#000000;  
}
/*** 

====================================================================
  Global Settings
====================================================================

 ***/

@font-face {
    font-family: 'FontAwesome';
    /* src: url('../fontawesome-webfont.eot');
    src: url('../fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('../fontawesome-webfont.woff') format('woff'), url('../fontawesome-webfont.ttf') format('truetype'), url('../fontawesome-webfont.svg#FontAwesome') format('svg'); */
    font-weight: normal;
    font-style: normal;
}


[class^="icon-"]:before, [class*=" icon-"]:before {
    /* font-family: FontAwesome; */
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit
}
@media (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1170px;
}
}
h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    padding: 0px;
    font-family: 'Century Gothic', sans-serif;
    font-weight: 600;
}
a.navbar-brand {
    height: auto;
}

ul, li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    font-family: 'Century Gothic', sans-serif;
}

a {
    text-decoration: none;
   /* font-family: 'Poppins', sans-serif;*/
   font-family: 'Century Gothic', sans-serif;
}

a:hover, a:focus {
    text-decoration: none;
    outline: none;
   font-family: 'Century Gothic', sans-serif;
}

p {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 28px;
    font-family: 'Century Gothic', sans-serif;
     margin-bottom: 0rem;
}
figure{
    margin: 0;
}


.color-black{
    color: #000 !important;
}



.mt-none{
    margin-top: 0 !important;
}
.mb-none{
    margin-bottom: 0 !important;
}




/*.overlay {
    background: #000000a3;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.5s ease;
}*/

.inner-overlay {
    width: 100%;
    float: left;
    background: #000000e8;
    padding: 100px 0px 0px 0px;
}

.inline-panel {
    display: inline-block;
    position: relative;
}

.flex-panel {
    display: flex;
    flex-wrap: wrap;
}

.full-width {
    width: 100%;
}
.commonpage-padding {
    padding: 130px 0px 0;
}
/*---- */
.white{
    color: #FFF !important;
}
.color-2{
    color: #f36d24 !important;
}

/*** 

====================================================================
  Scroll To Top style
====================================================================

***/

#scroll {
    position: fixed;
    z-index: 999;
    right: 12px;
    bottom: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: none;
    background-color: #252525;
    border-radius: 50px;
    /*box-shadow: 0px 0px 3px 4px rgb(123, 123, 123);*/
}

#scroll span {
    position: absolute;
    top: 25%;
    left: 31%;
    /*margin-left: -8px;
    margin-top: -17px;*/
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}

#scroll:hover {
    /*border: solid 2px #1785b4;*/
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
}

/*** 

====================================================================
  End Scroll To Top style
====================================================================

***/

.row.m0 {
    margin: 0px;
}
.prl-0{
    padding-left: 0;
    padding-right: 0;
}
.p0 {
    padding: 0px;
}
.mt-none{
    margin-top: 0 !important;
}
.plr-none{
    padding-left: 0;
    padding-right: 0;
}
.plr-5{
    padding-left: 5px;
    padding-right: 5px;
}
.pl-none{
    padding-left: 0;
}
.pb-none{
    padding-bottom: 0 !important;
}
.pr-none {
    padding-right: 0;
}

.padd-l-none {
    padding-left: 0;
}

.pd-left-50 {
    padding-left: 50px;
}
.m-t-100{
    margin-top: 100px;
}
.mt-30{
    margin-top: 30px;
}
.mt-10{
    margin-top: 10px;
}
.mr-btm-0 {
    margin-bottom: 0 !important;
}

.mb-60 {
    margin-bottom: 45px;
}
.mb-30 {
    margin-bottom: 30px;
}
.brd-r-0 {
    border-right: 0 !important;
}

.spacer-50 {
    width: 100%;
    height: 20px;
    float: left;
}

.bg-gray-light {
    background-color: #f7f7f7;
}

/*** 

====================================================================
  image block style
====================================================================

***/

img {
    max-width: 100%;
}

.image_thumb {
    overflow: hidden;
    /*display: inline-block;*/
    position: relative;
    z-index: 3;
    cursor: pointer;
    box-shadow: 0 0px 17px #d2cfcf;
    background-color: #FFF;
    padding: 6px;
}

.image_thumb:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    cursor: pointer;
}

.image_thumb img {
    width: 100%;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.image_thumb:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.image_thumb:hover:before {
    opacity: 1;
}

#success {
    display: none;
}

#error {
    display: none;
}

.display_table {
    display: table;
    width: 100%;
}

.display_table_row {
    display: table-row;
}

.display_table_cell {
    display: table-cell;
    float: none !important;
}

iframe {
    border: none;
}

i:before {
    margin-left: 0px !important;
}

/* Btn css*/

.btn-full {
    width: 100% !important;
    display: inline-block;
    text-align: center;
    padding: 15px 20px;;
}

.btn-xlg {
    padding: 15px 50px;
}

.btn-lg {
    padding: 12px 30px;
    display: inline-block;
}

.btn-md {
    padding: 15px 20px;
    display: inline-block;
}

.btn-xs {
    padding: 4px 30px;
    display: inline-block;
    font-size: 16px;
}
.theme-btn-2 .icn {
  float: right;
  margin-left: 10px;
}
.theme-btn-2{
    display: inline-block;
    /*padding: 6px 8px;*/
    background: #000;
    border: solid 1px #000;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    /*border-radius: 45px;*/
    border: none;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    outline: none;
    /*width: 100%;*/
    font-family: 'Century Gothic', sans-serif;
    margin-top: 20px;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.theme-btn-2 .icn{
    width: 43px;
    height: 43px;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 50%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}
.theme-btn-2:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #ec0582;
    
    left: 0px;
    top: 0px;

   /* border-radius: 45px;*/
    display: block;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform: scalex(0);
    -ms-transform: scalex(0);
    transform: scalex(0);
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}
.theme-btn-2:hover, .theme-btn-2:focus {
 
    color: rgb(255, 255, 255);
    text-decoration: none;
    outline: none;
}
.theme-btn-2:hover:before, .theme-btn-2:focus:before {
    outline: none;
    color: #000;
    /*border-radius: 45px;*/
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.theme-btn-white{
    display: inline-block;
    /*padding: 6px 8px;*/
    background: #FFF;
    border: solid 1px #000;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    /*border-radius: 45px;*/
    border: none;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    outline: none;
    /*width: 100%;*/
   font-family: 'Century Gothic', sans-serif;
    margin-top: 10px;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.theme-btn-white:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #ec0582;
    
    left: 0px;
    top: 0px;

   /* border-radius: 45px;*/
    display: block;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform: scalex(0);
    -ms-transform: scalex(0);
    transform: scalex(0);
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}
.theme-btn-white:hover:before, .theme-btn-white:focus:before {
    outline: none;
    color: #FFF;
    /*border-radius: 45px;*/
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.theme-btn-white:hover, .theme-btn-white:focus {
 
    color: rgb(255, 255, 255);
    text-decoration: none;
    outline: none;
}
.bg-none{
    background-color: transparent !important;
}
.border-white{
    border: 1px solid #FFF !important;
}


/* button -border */

.theme-btn-white-border{
    display: inline-block;
    background: transparent;
    border: solid 1px #FFF;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    /*border-radius: 45px;*/
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    outline: none;
    /*width: 100%;*/
   font-family: 'Century Gothic', sans-serif;
    margin-top: 10px;
    overflow: hidden;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.theme-btn-white-border:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #FFF;
    border: solid 1px #FFF;
    left: 0px;
    top: 0px;

   /* border-radius: 45px;*/
    display: block;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform: scalex(0);
    -ms-transform: scalex(0);
    transform: scalex(0);
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}
.theme-btn-white-border:hover:before, .theme-btn-white-border:focus:before {
    outline: none;
    color: #000;
    /*border-radius: 45px;*/

    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.theme-btn-white-border:hover, .theme-btn-white-border:focus {
 
    color: #000;
    text-decoration: none;
    outline: none;
     border: solid 1px #FFF;
}









.theme-btn {
    display: inline-block;
    padding: 14px 25px;
    background: #ffdc4f;
    border: solid 1px #fff;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    line-height: initial;
    border-radius: 4px;
    border: none;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    outline: none;
}

.theme-btn:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #ffc411;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    display: block;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform: scalex(0);
    -ms-transform: scalex(0);
    transform: scalex(0);
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}

.theme-btn:hover:before, .theme-btn:focus:before {
    outline: none;
    color: #000;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.theme-btn:hover, .theme-btn:focus {
    color: #000;
}

.theme-btn-second {
    color: #FFF;
    background-color: #4f5368;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 10px;
    float: left;
    letter-spacing: 0.3px;
}

.theme-btn-second:hover {
    background: #000;
    color: #FFF;
    text-decoration: none;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.theme-btn-brd {
    color: #649a38;
    background-color: #FFF;
    border: 2px solid #649a38;
    border-radius: 0px;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 30px;
    letter-spacing: 0.3px;
}

.theme-btn-brd:hover {
    background: #649a38;
    border: 2px solid #649a38;
    color: #FFF;
    text-decoration: none;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

/* End btn css*/

/* Main title css
============================================================================================ */

.sec-title {

}
.sec-title h1 {
    font-size: 55px;
    color: #242424;
    text-transform: uppercase;

}
.sec_middle_title h1::after {
    content: "";
    width: 50px;
    height: 2px;
    background: #000;
    display: block;
    position: relative;
    top: 10px;
    left: 0;
    margin: auto;
}
.sec_middle_title h1 span {
    font-size: 40px;
    color: #e6760c;
    font-weight: 300;
}
.sec_middle_title h2 span {
    color: #18ba60;
}
.sec_middle_title p {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: .34px;
}
.fullheight {
    height: 100%;
}
.commonouter-container {
    position: absolute;
    right: 0;
    bottom: 0;
}
/*CSS3 Bounce Animation*/

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(20px);
        opacity: 0;
    }
}

@-moz-keyframes bounce {
    0% {
        -moz-transform: translateY(0);
    }
    50% {
        -moz-transform: translateY(20px);
        opacity: 0;
    }
}

@-o-keyframes bounce {
    0% {
        -o-transform: translateY(0);
    }
    50% {
        -o-transform: translateY(20px);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
        opacity: 0;
    }
}

/*** 

====================================================================
  Main Slider style
====================================================================

***/

/*============ Start Service Section =============*/


.title {
    /* padding: 0 0 90px 0; */
}
.title-text {
    padding-left: 0px;
}

.who-i-am{
    background-color: #000;
    padding-bottom: 80px;
}

.who-i-am .main-wrap-outer{
    width: 100%;
}
.who-i-am .main-wrap-outer .inner-wrap{
    width: 100%;
    background-color: #FFF;
    bottom: 35px;
    position: relative;
    z-index: 1;
    padding: 70px 30px 50px 50px;
   /* box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 5%);*/

}
.who-i-am h1{
    font-size: 55px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: Century Gothic;
}
.who-i-am h4{
    font-size: 24px;
    margin-bottom: 20px;
    color: #ec0582;
    font-weight: 500;
    font-family: Century Gothic;
}
.who-i-am .head_form {
    width: 100%;
    float: left;
    padding: 20px;
    background-color: #f6f9fc;
}
.who-i-am .head_form .form-group {
    margin-bottom: 10px;
}
.who-i-am .head_form .form-group input {
    border: 1px solid #dedbdb;
    border-radius: 0px;
    background: #FFF;
    height: 45px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0px 10px;
    outline: none;
    font-size: 14px;
    color: #797979;
    letter-spacing: .51px;
    margin-bottom: 0;
}
.who-i-am .head_form .form-group textarea {
    border: 1px solid #dedede;
    border-radius: 0px;
    background: #FFF;
    height: 100px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 10px 10px;
    outline: none;
    font-size: 14px;
    color: #797979;
    letter-spacing: .51px;
    resize: none;
}
.who-i-am .tel-info {
    margin-top: 50px;
    font-size: 18px;
    color: #FFF;
}
.who-i-am .tel-info .tel-icon {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    margin-right: 15px;
    float: left;
    border-radius: 50%;
    background-color: var(--color-primary);
    justify-content: center;
    align-items: center;
    display: flex;
}
.who-i-am .tel-info .tel-num {
    float: left;
}
.who-i-am .tel-info .tel-num span {
    font-size: 16px;
    color: #000;
    font-weight: normal;
}
.who-i-am .tel-info .tel-num p {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

/*============ End First Section =============*/


.services{

}
.services .image-box{
position: relative;
overflow: hidden;
}
.img-hover-zoom--zoom-n-pan-v img {
  transition: transform .5s ease-in-out ;
  transform: scale(1.2);
  transform-origin:50% 0;
}

/* The Transformation */
 .img-hover-zoom--zoom-n-pan-v:hover img {
  transform: scale(1) translateX(0%);
}
.services .image-box .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 28%;
    left: 8%;
    text-align: left;
}
.services .image-box .text h2 {
    font-size: 40px;
    color: var(--color-primary);
    font-weight: 500;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 10px;
}

/*============ End Service Section =============*/

.aboutme{
    height: 100%;
    background-color: #000;
}
.aboutme .left-area{
    float: left;
    padding: 10% 0 10% 20%;
}
.aboutme .left-area h6{
    font-size: 20px;
    color: #FFF;
    font-weight: 200;
    text-transform: uppercase;
}
.aboutme .left-area h1{
    font-size: 60px;
    color: #FFF;
}
.aboutme .left-area h1:hover{
    font-size: 60px;
    color: #ec0582;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    transform-origin: bottom;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.aboutme .left-area p{
    color: #FFF;
}
.aboutme .right-area{

}
.aboutme .right-area .image-box{

}

/*=================== Strat Video Section ==================*/

.video-section{
    background-color: #FFF;
    background: url("https://codifystudioz.com/cs-theme/parlor/demo_1/img/resource/testi-1.jpg") no-repeat;
    background-size: cover;
    background-position: center;
}
[data-overlay-dark] .container, [data-overlay-light] .container {
    position: relative;
    z-index: 2;
}
.bg-fixed {
    background-attachment: fixed;
}
[data-overlay-dark]:before, [data-overlay-light]:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
[data-overlay-dark], [data-overlay-light] {
    position: relative;
}
[data-overlay-dark="4"]:before, [data-overlay-light="4"]:before {
    opacity: 0.4;
}
[data-overlay-dark]:before {
    background: #000;
}
/*.video-section:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
}*/
.video-media {
    width:100%;
    float: right;
    text-align: right;
    top: 25px;
    right: 0px;
}
.video-media .overlay {
    background:#000;
}
.video {
    height: 100%;
    width: 100%;
    display: table;
    text-align: center;
    /*position: absolute;*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.5s ease;
}
.video a {
    display: table-cell;
    vertical-align: middle;
}
.play-video {
    width: 98px;
    height: 98px;
    padding: 10px;
    background: rgba(0,0,0,0.7);
    text-align: center;
    border-radius: 50%;
    border: solid 2px #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    position: relative;
    webkit-animation: pulsewhite 3s infinite;
    -o-animation: pulsewhite 3s infinite;
    animation: pulsewhite 3s infinite;
}
.play-video:before {
    width: 65px;
    height: 65px;
    padding: 10px;
    background: #2bcc1a;
    border-radius: 50%;
    border: solid 2px #445462;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    bottom: 0;
    text-align: center;
    margin: auto;
    font-size: 45px;
    color: #fff;
    letter-spacing: 12px;
    font-weight: 800;
    cursor: pointer;
    position: relative;
}
.play-video i {
    /*width: 22px;
    height: 25px;*/
    line-height: 26px;
    color: #FFF;
    padding-left: 0px;
    text-align: center;
    border-radius: 50%;
    line-height: 32px;
    font-size: 26px;
    letter-spacing: -5px;
    
    /* -webkit-animation: pulsewhite 3s infinite;
    -o-animation: pulsewhite 3s infinite;
    animation: pulsewhite 3s infinite; */
    position: relative;
}
.play-video {
    
}

.play-video i img {
    margin-left:10px;
}

@keyframes pulsewhite {
    50% {
        box-shadow: 0 0 0 10px rgb(244, 249, 243),
            0 0 0 20px rgba(236, 247, 235, 0.53)
    }
}
/*================== End Video Section ==================*/





.text-marquee {
    padding: 30px 0px;
    background-color: #fff;
    z-index: 3;
    position: relative;
    overflow: hidden;
}
.marquee {
    background-color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    color: #fff;
    line-height: 65px;
    text-align: center;
    text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
}
.scrolling-text {
    white-space: nowrap;
   /* overflow: hidden;*/
   width: 100%;
   float: left;
    -webkit-animation: scroll-left 50s linear infinite;
    animation: scroll-left 50s linear infinite;
}
@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}



/* Start achivement area css */

.core-value{
    background-color: #f9f9f9;
}
.core-wrap{
    width: 100%;
    height: 616px;
    overflow: hidden;
    background-color: #000;
    background: url("./Asserts/core-bg.webp") #232122 no-repeat right;
}
.counter-area{
    width: 100%;
}
.achivement{
    padding: 100px 0;
    border-right: 1px solid #333232;   
}
.achivement.pr-37{
    padding-right: 37px;
}
.achivement h1 {
  color: #FFF;
  font-size: 70px;
  text-align: center;
  margin: 0;
  padding: 0;

}
.achivement h4 {
  color: #FFF;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;

}
.achivement .plus-size{
    font-size: 53px;
    display: inline;
    font-weight: 100;
    color: #FFF;
    position: absolute;
}
.pro-box{
    width: 100%;
    height: 100vh;
    padding: 90px 0;
    text-align: center;
    
}
.pro-box.bg-col-black{
    background-color: #0b0b0b;
}
.pro-box.bg-col-pink{
    background-color: #ec0582;
}
.pro-box.bg-col-skin{
    background-color: #c8aeab;
}
.pro-box .icon{

}
.pro-box h1{
    font-size: 20px;
    color: #FFF;
    font-weight: 600;
    margin-top: 10px;
}
/*--------------- End Counter Area --------------*/


.our-mission{
    
}
.our-mission .image-box{
width: 50%;
float: left;

    max-height: 532px;
}
.bg-img-mission{

   /* background: url(../img/resource/mission.jpg) no-repeat; */
   background-size: cover;
   background-position: center;
   
}
.our-mission .container{

}
.our-mission .cnt-box{
    padding: 120px 0 120px 50px;
    float: right;

}
.our-mission .cnt-box h1{
    text-transform: uppercase;
}


.testimonial-area{
    /* background: url(../img/resource/testimonial-bg.jpg) no-repeat;  */
    background-size: cover;
    background-position: center;

}
.testimonial-area .testimonial-inner{
    width: 100%;
    height: 100%;
    padding: 0px 0 0px;
}
.testimonial-area .quoteshape {
    position: relative;
    text-align: center;
}
.testimonial-area .section-head{
    margin-bottom: 50px;
    border-top: 1px solid #f2f2f2;
    padding-top: 41px;
}
.testimonial-block-outer{
    padding: 60px 0 60px 0;
    border-radius:0px;
    background-color: #FFF;
    border-radius: 8px;
      
}
.box-shadow{
    box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 11%) !important; 
}
.testimonial-block-outer .text-box{
    width: 100%;
    margin: auto;
    padding: 40px 50px 0px;
    color: #FFF;
    text-align: center;
}
.testimonial-block-outer .client-img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
    background-color: #fff;
    overflow: hidden;
    padding: 10px;
    border: 1px solid #ec0582;
}
.testimonial-block-outer .client-img img{
    border-radius: 50%;
}
.testimonial-block-outer .text-box p{
  text-align: justify;
    font-weight: 500;
}
.testimonial-block-outer .quote-icon{
    margin-bottom: 30px;
    margin-top: 20px;
    justify-content: center;
    display: flex;
}
.testimonial-block-outer .quote-icon img{
    width: auto;

}
.testimonial-block-outer .name{
    font-size: 20px;
    line-height: 30px;
    padding-top: 0px;
    text-align: center;
    color: var(--color-primary);
    margin-top: 30px;
}
.testimonial-block-outer .name span{
    font-size: 16px;
    font-weight: 300;
}
.testimonial-block-outer .rating{
    margin-top: 20px;
    text-align: center;
}
.testimonial-block-outer .rating img{
    width: auto !important;
    margin: auto;
}
.testimonial-area .owl-controls {
    position: absolute;
    right: 0px;
    top: -68px;
}
.testimonial-area .owl-dots {

    text-align: center;
    margin-top: 10px;
}
.testimonial-area .owl-dot {
    display: inline-block;
    padding: 0px 3px;
}
.testimonial-area .owl-dot span{
    width: 12px;
    height: 12px;
    display: inline-block;
    background:#000;
    border-radius: 100px;
}
.testimonial-area .owl-dot.active span{
    background:var(--color-primary);
}

/*------------------ End Testimonial -----------------------*/

.insta-photo{

}
.insta-photo.col-xl-4 {
    padding-left: 5px;

}
.prl-5{
    padding-right: 5px;
    padding-left: 5px;
}
.pleft-5{
    padding-left: 5px;
}
.insta-photo .image-box{
    margin-right: 0px;
    transition: all 0.3s ease-out;
    position: relative;
    display: block;
}
.insta-photo .image-box a::before {
    position: absolute;
    content: "\f16d";
    font-family: "Font Awesome 5 Brands";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 55%;
    transform: translateY(-50%);
    z-index: 3;
    color:  #fff;
    font-size: 40px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.4s;
    transition-delay: 0s;
}
.insta-photo .image-box a:hover::before {
    top: 50%;
    visibility: visible;
    opacity: 1;
    transition-delay: 0.4s;
}
.insta-photo .image-box a::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    z-index: 1;
   opacity: 0;
    background-color: rgba(236,5,130,0.9);
    visibility: hidden;
    transition: all ease 0.4s;
}
.insta-photo .image-box a:hover::after {
    height: 100%;
    opacity: 1;
    visibility: visible;

}

.newsletter-area{
    padding: 0 0 0 30px;
}
.newsletter-area h4{
    font-size: 22px;
}
.newsletter-area .newsletter{

}
.newsletter-area .newsletter input {
    width: 100%;
    float: left;
    height: 52px;
    padding: 12px 20px;
    background: #f2f2f2;
    color: #b0b0b0;
    font-size: 16px;
    font-weight: 400;
    border-radius: 0px;
    border: none;
}
.newsletter-area h6{
    font-size: 18px;
}







.gallery{

}
.gallery-bg{
    width: 100%;
    /* background: url(../img/resource/gallery-bg.jpg) no-repeat; */
    height: 515px;
}
.gallery-carousel {
    position: relative;
    display: block;
    max-width: 1000px;
    margin: 0 auto;
}
.gallery-carousel.owl-carousel .owl-stage-outer {
    overflow: visible;
    padding: 0px 0px 0px;
}
.gallery .sec-title{

}
.gallery .sec-title h1{

}
.project-one-single{
    position: relative;
    display: block;
    margin-bottom: 0px;
    overflow: hidden;
}
.project-one-img-box {
    position: relative;
    display: block;
}
.project-one-img {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    height: 515px;
   /* border-left: 5px solid #FFF;
    border-right: 5px solid #FFF;*/
}
.project-one-img img{
    width: 100%;
    transition: all 500ms ease;
}
/*.project-one-img:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
    z-index: 1;
    background-color: rgba(254,146,73,.70);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(-70%);
    transform: translateY(-70%);
    z-index: 1;

}
.project-one-single:hover .project-one-img:before {
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
}*/
.project-title{
    padding: 15px 15px;
    text-align: center;
    z-index: +1;
    background-color: rgba(255,255,255,0.9);
    position: relative;
    bottom: 126px;
    width: 100%;
    margin: auto;

}

 .project-title a{
    float: left;
 }
.project-title h2{
    text-transform: capitalize;
    font-size: 22px;
    text-align: left;
    color: #000;
    line-height: 60px;
}
.project-title a.arrow{
    float: right;
    width: 53px;
    height: 53px;
    background-color: #000;
    border-radius: 50%;
    margin-top: 10px;
    color: #FFF;
    justify-content: center;
    align-items: center;
    display: flex;
}
.gallery-single {
    position: relative;
    display: block;
    margin-bottom: 15px;
}
.gallery1-img {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
}
.gallery1-carousel {
    position: relative;
    display: block;
    max-width: 700px;
    margin: 0 auto;
}

.gallery .owl-nav .owl-prev, .gallery .owl-nav .owl-next {
    position: absolute;
    top: 40%;
    background: #ffffffeb;
    display: inline-block;
    width: 52px;
    height: 52px;
    color: #171717;
    font-size: 32px;
    text-align: center;
    line-height: 55px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    cursor: pointer;
    border: solid 1px #e8e5e5eb;
    border-radius: 100%;
    opacity: 0;
    visibility: hidden;
}
.gallery .owl-nav .owl-prev {
    left: -10px;
}
.gallery .owl-nav .owl-prev i {
    margin-left:-6px;
}
.gallery .owl-nav .owl-next {
    right:-10px;
}
.gallery .owl-nav .owl-next i{
    margin-right:-6px;
}
.gallery:hover .owl-nav .owl-prev, .gallery:hover .owl-nav .owl-next {
    opacity: 1;
    visibility: visible;
}

/*** 
====================================================================
  Start Client Logo Section
====================================================================
***/
#clients {
   
    /*background-color: #f2f2f2;*/
    border-bottom: 1px solid #f2f2f2;
}
#clients .owl-carousel {
  padding: 20px 70px;
  position: relative;
}
#clients .container .item {
  /*height: 145px;
  line-height: 145px;*/
}
#clients .container .item img {
  transition: all 0.3s ease 0s;
  display: inline;
}
#clients .owl-controls {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
#clients .owl-nav {
  margin: 0;
}
#clients .owl-nav div {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
#clients .owl-prev {
  left: 0px;
  position: absolute;
  top: 0;
  line-height: 145px;
}
#clients .owl-nav div .fa {
  color: #b9b9b9;
  font-size: 30px;
}
#clients .owl-nav div {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
#clients .owl-next {
  position: absolute;
  right: 0px;
  top: 0;
  line-height: 145px;
}
#clients .owl-nav div .fa {
  color: #b9b9b9;
  font-size: 30px;
}
/* End client section css*/

.blog-section {
    width: 100%;
    background-color: #FFF;
}

.newsblog-group {
    width: 100%;
    float: left;
    padding: 0px;
    background: #fff;
    border: none;
    overflow: hidden;
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.10980392156862745);
}

.newsblog-imgpanel {
    width: 100%;
    display: inline-block;
    position: relative;
}
.newsblog-imgpanel img {
    width: 100%;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
    transform-origin: bottom;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.newsblog-group:hover .newsblog-imgpanel img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.newsblog-date-box {
 
    padding: 0;
    /*background: #0bb9f8;*/
    text-align: center;
    z-index: 3;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: auto;
    color: #FFF;
    font-size: 16px;
    line-height: 28px;
    /*border: 1px solid rgba(255,255,255,0.3);*/
    
}
.newsblog-date-box .blog-date {
    display: block;
    padding-bottom: 3px;
    font-size: 16px;
    padding: 5px 15px;
    background-color: #000;
}
.newsblog-date-box .blog-date strong{
    font-size: 22px;
}
.newsblog-date-box .blog-year {
    display: block;
    padding-bottom: 3px;
    font-size: 16px;
    padding: 5px 15px;
    background-color: #000;
    border-top: 1px solid rgba(255,255,255,0.5);
}
.newsblog-date small {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.newsblog-conents {
    width: 100%;
    float: left;
    background: #fff;
    padding:25px 20px;
    position: relative;
    z-index: 1;
    transition-property:margin-top;
    transition-duration: .5s;
    transition-timing-function: linear;
    transition-delay: .1s;
}
.blog-auth-area{
    border-bottom: 1px solid #ddd8d8;
    padding: 10px;

}
.blog-auth-area a{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    border-right: 1px solid #ddd8d8;
    padding-right: 20px;
    margin-right: 10px;
}
.blog-auth-area a:last-child{
    border-right: none;
    margin-right: 0px;
}
.newsblog-info {
    width: 100%;
    display: table;
    padding: 12px 0px;
    border-top: solid 1px #e8e8e8;
    border-bottom: solid 1px #e8e8e8;
}

.newsblog-conents a h3 {
    padding-top: 30px;
    font-size: 18px;
    color: #0a0b0b;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 28px;

}
.newsblog-conents h3 {
    
}
.newsblog-conents p{
    color: #0bb9f8;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: capitalize;
}



/*...................End Latestpost-section-End..............*/



/*--------------- Start Home 2 page css ---------------------*/

.who-iam{
    background-color: #000;
    overflow: hidden;
}
.who-iam .who-img-box{

}
.who-iam .who-img-box{
    width: 100%;
    float: left;
    height: 532px;
}
.who-iam .who-iam-img{

   background: url("./Asserts/webp/PR\(31\).webp") no-repeat;
   background-size: cover;
   background-position: center;
   
}
.who-iam .content-box{
    padding: 50px 0px 50px 50px;
}
.who-iam .content-box h1{

}
.who-iam .content-box h5{
    font-size: 20px;
    font-weight: 600;

}


@media only screen and (min-width: 320px) and (max-width: 735px){

    .who-iam .content-box{
        padding: 50px 0px 50px 0px;
    }
}

    

/*-----------------End who-iam section---------------------*/

.why-choose{
    
    overflow: hidden;
}
.bg-col-yellow{
    background-color: #f7e090 !important;
}
.why-choose .image-box{

}
.why-choose .fea-box{
    width: 100%;
   background-color: #000;
}
.why-choose .fea-box .img-box{
    padding: 20px;
}
.why-choose .fea-box .content-box{
    padding: 0 20px 20px;
}
.why-choose .fea-box .content-box h4{
    font-size: 20px;
}
.why-choose .fea-box .content-box p{
    font-size: 16px;
    line-height: 26px;
}
.why-choose .btm-border{
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    height: 1px;
    background: black;
}
.why-choose .r-more{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}






/*---------------- END WHY-CHOSE SECTION -----------------*/

/* Start Service 2 css*/

.service2{
   
}
.service2:before {
  content: '';
  width: 100%;
  background-color: white;
  height: 320px;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
}
/* .service2:before {
    content: '';
    width: 100%;
    background-color: #FFF;
    height: 320px;
    position: absolute;
} */

.bg-black{
    width: 100%;
   /* background: url(../img/resource/gallery-bg.jpg) no-repeat;*/
   background-color: #000;
   padding-bottom: 150px;
   /* height: 515px;*/
}
.gallery-carousel {
    position: relative;
    display: block;
    max-width: 1000px;
    margin: 0 auto;
}
.gallery-carousel.owl-carousel .owl-stage-outer {
    overflow: visible;
    padding: 0px 0px 0px;
}
.service2 .sec-title{

}
.service2 .sec-title h1{

}
.service2 .service-one-single{
    position: relative;
    display: block;
    margin-bottom: 0px;
    overflow: hidden;
}
.service2 .service-one-img-box {
    position: relative;
    display: block;
    border: 1px solid #000;
    padding: 10px;
    background-color: #000;
}
.service2 .service-one-img {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
  /*  height: 515px;*/
   /* border-left: 5px solid #FFF;
    border-right: 5px solid #FFF;*/
}
.service2 .service-one-img img{
    width: 100%;
    transition: all 500ms ease;
    object-fit: cover;
}
/*.project-one-img:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease, -webkit-transform 500ms ease;
    z-index: 1;
    background-color: rgba(254,146,73,.70);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(-70%);
    transform: translateY(-70%);
    z-index: 1;

}*/
.service-one-single:hover .service-one-img:before {
    visibility: visible;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
}
.service-title{
    padding: 15px 15px;
    text-align: center;
    z-index: +1;
    background-color: rgba(255,255,255,0.9);
    /*position: relative;
    bottom: 126px;*/
    width: 100%;
    margin: auto;

}

 .service-title a{
    float: left;
 }
.service-title h2{
    text-transform: capitalize;
    font-size: 22px;
    text-align: left;
    color: #000;
    line-height: 60px;
}
.service-title a.arrow{
    float: right;
    width: 53px;
    height: 53px;
    background-color: #000;
    border-radius: 50%;
    margin-top: 10px;
    color: #FFF;
    justify-content: center;
    align-items: center;
    display: flex;
}

/* owl nav */
.service2 .owl-nav .owl-prev, .service2 .owl-nav .owl-next {
    position: absolute;
    bottom: -90px;
    background: transparent;
    display: inline-block;
    width: 52px;
    height: 52px;
    color: #fff;
    font-size: 32px;
    text-align: center;
    line-height: 55px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    cursor: pointer;
    border: solid 1px #f7e090;
    border-radius: 0;
    opacity: 1;
    visibility: visible;
}
.service2 .owl-nav .owl-prev {
    left: 45%;
}
.service2 .owl-nav .owl-prev i {
    margin-left:-6px;
}
.service2 .owl-nav .owl-next {
    right:45%;
}
.service2 .owl-nav .owl-next i{
    margin-right:-6px;
}
.service2 .service-one-img-box:hover{
    border: solid 1px #ec0582;
    transition: all 500ms ease;
}
/*.service2:hover .owl-nav .owl-prev, .service2:hover .owl-nav .owl-next {
    opacity: 1;
    visibility: visible;
}*/


/*---------------- END SERVICE2 SECTION -----------------*/


.our-product{

}
.our-product .product-box{

}
.our-product .product-img-box{
width: 100%;
   
    position: relative;
    overflow: hidden;
   
}

.our-product .product-name{
    width: 100%;
    padding: 20px 0;
    background-color: #000;
    text-align: center;
}
.our-product .product-name h2{
    color: #FFF;
    font-size: 18px;
}

/*---------------- END OUR PRODUCT SECTION -----------------*/


.make-appoitment{
    

}
.bg-img-4{
    background: url(./Asserts//bg4.webp);
    background-position: center;
}
.make-appoitment .appoint_form{
    background-color: #FFF;
}
.make-appoitment .appoint_form .title{
    background-color: #000;
    width: 100%;
    padding: 30px;
    margin-bottom: 10px;
}
.make-appoitment .appoint_form .title h3{
    font-size: 30px;
    color: #FFF;
    text-transform: uppercase;
   
}
.make-appoitment .appoint_form p{
    color: #FFF;
    font-size: 15px;
    padding-top: 10px;
}
.make-appoitment .appoint_form{
    width: 100%;
    background-color: #f7e090;
    padding: 20px;

}
.make-appoitment .appoint_form .form-group input {
    border: 1px solid #d3ba66;
    border-radius: 0px;
    background: #FFF;
    height: 45px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0px 24px;
    outline: none;
    font-size: 15px;
    color: #797979;
    letter-spacing: .51px;
    margin-bottom: 0;
}
.make-appoitment .appoint_form .form-group textarea {
    border: 1px solid #d3ba66;
    border-radius: 0px;
    background: #FFF;
    height: 100px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 10px 24px;
    outline: none;
    font-size: 15px;
    color: #797979;
    letter-spacing: .51px;
    resize: none;
}
.make-appoitment .appoint_form .form-group {
    margin-bottom: 5px;
}



/*===================================================commonbanner-breadcrumb-css-Start===================================================*/
.banner_area {
   /* margin-top:120px;*/
    margin-top: 0;
    padding: 250px 0 100px;
    background-size: cover;
    /*min-height: 352px;*/
    position: relative;
    z-index: 2;
}
.banner_area .container {
    height: 100%;
}
.banner_area .content-align {
    display: table;
    height: 100%;
    margin: auto;
}
.content-center {
    display: table-cell;
    margin: auto;
    vertical-align: middle;
}
.banner_area.banner_one{
    background:url("./Asserts/banner.webp") no-repeat scroll center center;
    background-size: cover;
}
.banner_area:before {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.banner_area .banner_content h3 {
    padding:30px 0 10px 0;
    color: #fff;
    font-size: 65px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
}
.banner_link {
    width: 100%;
    overflow: hidden;
    text-align: center;
}
.banner_link .abnner_link_inner a {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    letter-spacing: .32px;
    margin-right: 20px;
    line-height: 50px;
    position: relative;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
}
.banner_link .abnner_link_inner a:before {
    content: "\f715";
    font-family: "Font Awesome 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    left: -18px;
    top: 50%;
    -webkit-transform: rotate(70deg) translatex(-44%);
    -ms-transform: rotate(70deg) translatex(-44%);
    transform: rotate(70deg) translatex(-44%);
    color: #fff;
}
.banner_link .abnner_link_inner a:last-child {
    margin-right: 0px;
}
.banner_link .abnner_link_inner a:first-child:before {
    display: none;
}
.banner_link .abnner_link_inner a:hover, .banner_link .abnner_link_inner a.active {
    color: #fff;
    text-decoration: none;
}
/*.banner_area_plane {
    background: url(../img/banner/banner-bg.jpg) no-repeat scroll center center;
    background-size: auto auto;
    background-size: cover;
    min-height: 235px;
    position: relative;
    z-index: 2;
}
.banner_area_plane::before {
    content: "";
    background:rgba(143, 143, 143, 0.5);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.banner_area_plane .banner_content h3 {
    padding-top: 100px;
    font-size: 38px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}*/

/* End Banner Area*/

/* ------------ About Page Css Start ---------------*/


.com-about{
    background-position: left;
    height: 100vh;
    padding: 200px 0 0 0;
    position:relative;
}
.bg-img-5{
     
    /* background: url(../img/resource/bg5.jpg) left no-repeat; */
    background-size: cover;
}
.company-about{
    padding: 100px 0;
}
.company-about-img{

}
.company-content-area{
    padding-left: 30px;
}
.com-about h6{
    font-size: 16px;
    color: #000;
    letter-spacing: 2px;
    text-transform: uppercase;
    
}
.com-about h1{
    font-size: 40px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
}
/*------------ End COmpany About Content Css -----------*/

/* resonsive start */


@media (max-width: 1400px){
  .main_slider_area .rev_slider .tp-leftarrow {
      left: -35px !important;
  }
  .main_slider_area .rev_slider .tp-rightarrow {
      left: 103% !important;
  }
  .footer_up .form-container .btn-full {
      padding: 11px 40px;
  }
}
@media (max-width: 1300px){
  .slider_area .carousel-captions .heading {
      font-size: 40px;
   }
   .testimonial-section {
      padding-top: 80px;
   }
   /*1300.............testimonial-section-End............*/

}

@media (max-width:1200px){
  /*............1200slider_area mr_top-Start............*/
  /*.slider_area .carousel-captions.caption-align-center {
      width: 60%;
  }*/
  .slider_area .carousel-captions .heading {
      font-size: 35px;
  }
  .slider_area .carousel-captions .heading {
      line-height: 38px;
  }
  .slider_area .parent-btn {
      margin-top: 30px;
  }
  /*............1200slider_area mr_top-End............*/
  

}

/* small computer device */

@media (max-width: 1024px){
  h1{
      font-size: 35px;
  }
  p{
      font-size: 15px;
  }
   .service-title h2{
      font-size: 16px;
  }

  /*---------------- START WHO I AM SECTION START ------------------*/

  .who-i-am {
      padding: 100px 0;
  }
  .who-i-am h4{
      font-size: 18px;
  }
  .who-i-am .main-wrap-outer .inner-wrap{
      bottom: 0;
  }
  
  /*---------------- END WHO I AM SECTION START ------------------*/

  .aboutme .left-area {
      float: left;
      padding: 10% 0 10% 5%;
  }


  .pro-box h1{
      font-size: 16px;
  }
  .our-mission .cnt-box{
      padding: 120px 0 70px 50px;
      float: right;
  }

  /* End Slider Css */



  /* Start testimonial Section*/

  .testimonial-area{
     
  }
  .testimonial-area .testimonial-inner{
      padding: 0 0 100px 0 ;
  }
  .testimonial-area .section-head{
      text-align: center;
  }
  .testimonial-area .container-fluid{
      max-width: 960px;
  }
  .testimonial-area .main-frame{
      margin: 0;
  }



  /* Start clients Section*/
  #clients .owl-nav .owl-prev{
      width: 45px;
      height: 45px;
      line-height: 135px;
  }
  #clients .owl-nav .owl-next{

      width: 45px;
      height: 45px;
      line-height: 135px;  
  }

   /* Start Blog Section*/

   #blog{
      padding: 100px 0;
   }
   #blog .img-box img{
      margin: auto;
      display: flex;
   }
   #blog .cnt-col{
      width: auto;
   }

  /*-------------------- Start Footer ------------------*/
  .footer-widget .opening-hour span{
      margin-left: 160px;
  }

  /*--------------- Start Home Page 2 ------------------*/

  .why-choose .fea-box .content-box h4{
      font-size: 16px;
  }
  .our-product .product-name h2{
      font-size: 14px;
  }
  .make-appoitment .appoint_form .title h3{
      font-size: 25px;
  }
  .com-about{
      padding: 100px 0;
      height: inherit;
  }

 

}

/* tablet device */

@media (max-width: 992px){

  .pr-md{
      padding-right: 15px;
  }
   .com-about {
      background-image: none;
      padding: 100px 0;
  }
  .offset-xs-0{
      margin-left: 0;
  }

  /* 992............Banner-End............*/


  .sec-title h1{
      font-size: 40px;
  }
  .services .image-box .text h2{
      margin-bottom: 10px;
      letter-spacing: 0;
  }

  /*-------------- Start aboutme  section ---------------*/
  .aboutme{
      padding: 120px 0;
  }
  .aboutme .right-area{
      margin-top: 20px;
  }
  .aboutme .left-area{
      padding: 0;
  }
  .aboutme .left-area h1{
      font-size: 40px;
  }
  /*-------------- End aboutme section ------------------*/

  /*-------------- Start core-value section -------------*/
  .core-wrap{
      background-image: none;
      overflow: inherit;
      height: auto;
  }
  .our-mission .image-box{
      width: 100%;
      /* height: 100vh; */
  }
  .our-mission .cnt-box{
      padding: 100px 0px;
      float: right;
  }
  .achivement{
      padding: 50px 0;
  }
  .achivement h1{
      font-size: 50px;
  }
  .pro-box{
      height: auto;
      padding: 50px 0;
  }
  /*-------------- Start core-value section -------------*/


  /*-------------- Start testimonial-area --------------*/
  .testimonial-block-outer{
      width: auto;
  }
  .testimonial-block-outer .text-box{
      width: 100%;
      padding: 30px;
  }
  .testimonial-block-outer .quote-icon {
      margin-bottom: 30px;
  }
  .testimonial-area .owl-nav .owl-prev{
      margin-right: 0;
      left: 75%;
      right: inherit;
  }
  .testimonial-area .owl-nav .owl-next{
      margin-right: 0;
      right: 0;
      left: inherit;
  }
  /*-------------- End testimonial-area --------------*/

  /*----------- Start blog section css --------------*/
  .newsblog-conents{
      padding: 25px 15px;
  }
  .blog-auth-area{
      padding: 0 0 10px 0;
  }
  .blog-auth-area a{
      font-size: 14px;
      padding-right: 15px;
  }
  .newsblog-conents a h3{
      font-size: 14px;
      line-height: 24px;

  }
  /*----------- End blog section css --------------*/

  .newsletter-area{
      padding: 20px 0 0 0;
  }

  /*-------------- Strat Footer -------------------*/

  .banner_area{
      /* margin-top: 90px; */
  }

  /*=========== START HOME PAGE 2 =============*/


  /*----------- Start service 2 section css -------*/

  .service2 .owl-nav .owl-prev {
      left: 40%;
  }
  .service2 .owl-nav .owl-next {
      right: 44%;
  }
 
}

/*@media (max-width: 767px){
 
  .slider_area.mr_top {
      margin-top: 85px;
  }
  .slider_area .carousel-captions .heading {
      font-size: 18px;
  }
  .slider_area .carousel-captions p {
      font-size: 12px;
  }
  .slider_area .carousel-captions p i {
      width: 8%;
  }

  



}*/

@media only screen and (min-width: 320px) and (max-width: 735px){


  .pr-0, .px-0 {
      padding-right: 15px!important;
  }
  .prl-0 {
      padding-left: 15px;
      padding-right: 15px;
  }
  .pl-0{
      padding-left: 15px !important;
  }
  .mt-mob-d-30{
      margin-top: 30px;
  }

  .sec-title h1{
      font-size: 30px !important;
  }
  p{
      font-size: 14px;
      line-height: 24px;
  }

 .pr-xs-none{
      padding-right: 0;
  }
  .mb-xs-none{
      margin-bottom: 0 !important;
  }
  .section-padding{
      padding: 100px 0;
  }
  /*---------- Start section tilte -----------------*/
  .title-text{
      margin-top: 10px;
  }
  /*---------- End section tilte -------------------*/


  /*---------- Start who-i-am section --------------*/

  .who-i-am {
      padding: 100px 0;
  }
  .who-i-am h1{
      font-size: 35px;
  }
  .who-i-am h4{
      font-size: 18px;
  }
  .who-i-am .main-wrap-outer .inner-wrap{
      bottom: 0;
      padding: 70px 15px 50px 15px;
  }
  .who-i-am .tel-info{
      width: 100%;
      float: left;
      margin-bottom: 20px;
  }

  /*---------------- END WHO I AM SECTION START ------------------*/

  .core-wrap{
      height: auto;
  }
  .achivement{
      border-bottom: 1px solid #333232;
  }
  .achivement.pr-37{
      padding-right: 0;
  }
  .achivement h1{
      font-size: 50px;
      
  }
  .pro-box{
      height: auto;
  }

  .make-appoitment .appoint_form .title h3 {
      font-size: 22px;
  }
 .service-box-content{
      width: 100%;
      padding-left:0; 
 }

 .service-box-inner h4::after{
      left: -16px;
      margin: 0;
 }
 .why-choose{
      height: 100%;
 }
 .counter-area{
      position: inherit;
 }
 .why-choose .m-120-mb{
      margin-top: 120px;

 }
 .achivement-area{
      border-radius: 0;
 }
 .pd-150{
      padding-left: 15px;
 }
 .contractor-img img{
      display: none;
 }
 .contractor-phn-info{
      margin: auto;
      display: table;
 }
  /*======================== End =======================*/

  .project-one-img{
      height: 409px;
  }

  .testimonial-area {
      padding: 120px 0;
  }
  .testimonial-area .testimonial-inner{
      padding: 0;
  }
 
  .testimonial-block-outer{
      padding: 30px;
  }
  .testimonial-block-outer .client-img{
      float: none;
  }
  .testimonial-block-outer .text-box {
      width: 100%;
      padding: 30px 0 0 0px;
  }
  .testimonial-area .owl-nav .owl-prev {
      margin-right: 0;
      left: 58%;
      right: inherit;
      top: 93%;
      width: 70px;
      height: 70px;
      line-height: 46px;
  }
  .testimonial-area .owl-nav .owl-next{
      top: 93%;
      width: 70px;
      height: 70px;
      line-height: 46px;
  }
  .project-one-img:before{
      display: none;
  }

      /*............Start video Section............*/


  /*----------- End book-consul -----------------*/

  .blog-section{
      
  }
  .newsblog-group{
      margin-bottom: 20px;
  }



  /*............Start service Section............*/

  .service-section .owl-nav .owl-prev{
      left: 34%;
  }
  .service-section .owl-nav .owl-next{
      right: 34%;
  }
 .service2 .owl-nav .owl-prev {
      left: 34%;
  }
  .service2 .owl-nav .owl-next {
      right: 34%;
  }


  /*............Start gallery Section............*/

  .gallery .section-padding{
     padding-bottom: 0;
  }

  .main-header .header-menu{
      display: none;
  }
  .banner_area{
      /* margin-top: 90px; */
  }
  .whoweare-section{
      padding: 50px 0;
  }
  .whoweare-content, .whoweare_main, .callappointment_main{
      padding-left: 0;
  }
  .about-page .whoweare-content h3{
      font-size: 22px;
      line-height: 28px;
      padding: 30px 0 15px 0;
      text-align: left;
  }
  .about-page .whoweare-content p{
      padding: 0;
      text-align: left;
  }


  .company-content-area{
      padding: 30px 0 0 0 ;
  }
  .core-value .single-core{
      padding: 30px 0 30px 0;
  }
/*    .founder .founder-info{
      margin: 0;
  }*/
  .com-about {
      background-image: none;
  }
  .offset-xs-0{
      margin-left: 0;
  }
  .pb-xs-0{
      padding-bottom: 0 !important;
  }
  .bg-img-4 {
      /* background: url(../img/resource/bg4.jpg); */
      background-position: revert;
  }

}

/* responsive end */
/* slider start */

.slider_area {
  min-width: 0;
  /* min-height: calc(100vh - 165px); */
  overflow: hidden;
  background: #eceef3;
  position: relative;
  height: 100vh;
  position: relative;
}
.slider_area.mr_top {
  margin-top: 150px;
}
.slide-img-1 {
  /* background: url(../img/home-slider/slide-1.jpg); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.slide-img-2 {
  /* background: url(../img/home-slider/slide-2.jpg); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}
.slide-img-3 {
  /* background: url(../img/home-slider/slide-3.jpg); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}
[data-overlay-dark]:before, [data-overlay-light]:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
}
.slider_area [data-overlay-dark]:before {
  background: #000;
  opacity: 0.6;
}
.slider_area [data-overlay-light]:before {
  background: #FFF;
}
.slider_area .item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: top;
}
.slider_area .owl-item {
  height: 100vh;
  position: relative;
}
/* .slider_area.h-none{
  min-width: 0;
  height: auto !important;
  overflow: hidden;
} */
.slider_area .container-fluid {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}
.slider_area .row {
  height: 100%;
}
.slider_area .col-lg-12 {
  position: relative;
  padding: 0;
  height: 100%;
}
.slider_area .slide {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: right;
}
/*.nobanner-slider.slider_area .slide {
  background: url(../img/home-slider/slide-1.png) 120% top no-repeat;
  
}*/
.slider_area .slide img {
  max-width: 100%;
}
.slider_area::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.slider_area .middle{
  position: absolute;
  width: 100%;
  top: 60%;
  left: 0;
  z-index: 1;
      transform: translate(0%, -50%);
}
.slider_area h1{
  color: #FFF;
  font-size: 70px;
  margin-bottom: 10px;
  font-weight: bold;
 text-transform: uppercase;
 /*font-family: 'Roboto', sans-serif;*/
}
.text_stroke{
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255 255 255 / 60%);
  color: transparent;
}
.slider_area h2{
  color: #FFF;
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 20px;


}
.slider_area h3{
  color: #FFF;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.slider_area h3:before {
  content: "";
  width: 76px;
  height: 1px;
  background: rgba(255,255,255,0.5);
  position: absolute;
  left: 207px;
  top: 21px;
  display: none;
}
.slider_area p{
  font-size: 16px;
  color: #FFF;
  margin-bottom: 30px;
}


.slider_area .owl-nav [class*=owl-] {
  width: 37px;
  height: 37px;
  color: #242424;
  line-height: 35px;
  padding: 0;
  background: transparent;
  border: 1px solid #242424;
  text-align: center;
  border-radius: 0;
  font-size: 20px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.slider_area .owl-nav .owl-prev, .slider_area .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin-right: 10px;
  color: #FFF;
  font-size: 26px;
  margin: 5px;
  padding: 0px;
  background:rgba(255,255,255,0.2);
  display: inline-block;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 5px;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 100%;
  line-height: 64px;
}
.slider_area .owl-nav {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  margin: auto;
}
.slider_area .owl-nav .owl-prev {
  left: 30px;
}
.slider_area .owl-nav .owl-next {
  right: 30px;
 
}
/*.slider_area:hover .owl-nav .owl-prev, .slider_area:hover .owl-nav .owl-next{
  background: #f16c24;
  color: #fff;
}*/
.slider_area .owl-nav .owl-prev:hover{
  background: var(--color-primary);
  color: #fff;
}
.slider_area .owl-nav .owl-next:hover{
  background: var(--color-primary);
  color: #fff;
}

/*...................End Slder...................*/

.parallax-header {
  background-position: center;
  height: 100vh;
  z-index: -1;
  position:relative;
}
.parallax-header:before {
  content: "";
  /*background: rgba(0, 0, 0, 0.6);*/
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.p-dlide-1{
   /* background: url(../img/home-slider/slide-1.jpg); */
   z-index: 1;
  position: relative;
}
.ban-img-1{
   background: url(./Asserts//banner-single.webp);
   z-index: 1;
  position: relative;
}
.z-up{
  z-index: 1;
  position: relative;
}
/*.p-dlide-1:before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 1;
  opacity: 0.2;
}*/
/*[data-overlay-dark] .container {
  position: relative;
  z-index: 2;
}*/
[data-overlay-dark="6"]:before {
  opacity: 0.6;
}
[data-overlay-dark]:before {
  background: #000;
}
.bg-fixed {
  background-attachment: fixed;
}
.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}
.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.v-bottom {
  position: absolute;
  width: 100%;
  bottom: 5%;
  left: 0;
  -webkit-transform: translate(0%, -5%);
  transform: translate(0%, -5%);
  z-index: 9;
}
.parallax-header h1{
  color: #FFF;
  font-size: 60px;
  margin-bottom: 10px;
  font-weight: bold;
 text-transform: uppercase;
}
.parallax-header h3{
  color: #FFF;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding-top: 100px;
}

.video-fullscreen-wrap{
  height: 100vh;
  overflow: hidden;

}
.video-fullscreen-video{
  height: 100%;
  width: 100%;
}
.video-fullscreen-wrap video {
  width: 100vw;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
[data-overlay-dark="7"]:before {
  opacity: 0.7;
}
[data-overlay-dark]:before {
  background: #000;
}
.v-middle{
  position: absolute;
  width: 100%;
  top: 60%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  z-index: 9;
}
.video-fullscreen-wrap h1 {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  position: relative;
  line-height: 1.25em;
  text-transform: uppercase;
}
.video-fullscreen-wrap h3{
  color: #FFF;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 4px;
}



@media (max-width: 1024px){

  .slider_area .middle{
      left: 50px;
  }
  .slider_area h1{
      font-size: 100px;
      line-height: 100px;
  }
  .slider_area .owl-item {
      height: 100vh;
      position: relative;
  }
}

@media (max-width: 992px){

  .slider_area{
      height: auto;
  }
  .slider_area.mr_top {
      margin-top: 90px;
  }
  .parallax-header h1{
      font-size: 40px;
  }
  .slider_area .middle{
      left: 0;
  }
  .slider_area h1{
      font-size: 40px;
      line-height: 53px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 735px){

  .slider_area .item{
      height: 85vh;
  }
  .slider_area h1{
      font-size: 28px;
      margin-bottom: 10px;
      line-height: 45px;
      text-transform: uppercase;
  }
  .slider_area h2{
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      padding: 0 15px;
  }
  .slider_area h3{
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 35px;
  }
  .slider_area h3:before {
      display: none;
  }
  .slider_area .middle{
      top: 50%;
      left: 0;
  }
  .slider_area .text-left{
      text-align: center !important;
  }
  .slider_area .btn-lg{
      padding: 0px 15px;
      font-size: 14px;
      border-radius: 0;
  }
  .slider_area .owl-nav .owl-prev , .slider_area .owl-nav .owl-next{
      width: 35px;
      height: 35px;
      font-size: 15px;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
  }
  .slider_area .owl-item {
      height: 75vh;
      position: relative;
  }
  .slider_area .owl-controls {
      position: absolute;
      right: 0px;
      top: -68px;
  }
  .slider_area .owl-dots {
      text-align: center;
      bottom: 32px;
      z-index: +1;
      position: relative;
      margin: -12px
  }
  .slider_area .owl-dot {
      display: inline-block;
      padding: 0px 5px;
  }
  .slider_area .owl-dot span{
      width: 12px;
      height: 12px;
      display: inline-block;
      background:#cccccc;
      border-radius: 100px;
  }
  .slider_area .owl-dot.active span{
      background:#ec0582;
  }
  .video-fullscreen-wrap h1 {
      font-size: 30px;
  }
  .video-fullscreen-video {
      height: 100%;
      width: 100%;
  }
  .bg-fixed {
      background-attachment: fixed;
      background-position: right;
  }
  .parallax-header:before {
      content: "";
      background: rgba(0, 0, 0, 0.6);
  }
}
/* slider end */
.services-img{
    height: 450px;
}

.gallery-images-cards{
    height: 100%;
    width: 100%;
    min-height: 300px;
    min-width: 300px;
}
@media(max-width:1399px){
    .banner_area .banner_content h3{
        font-size: 26px;
    }
}
@media(max-width:700px){
    .banner-title{
      font-size: 24px;
    text-align: center;
    line-height: 43px;
    }
  }
  .what-we-do-grid{
    max-height: 250px;
    width: 100%;
    object-fit: cover;
  }