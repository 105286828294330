/* Start single service page css */
.mb-90 {
    margin-bottom: 90px !important;
}
/* ======= Projects style  ======= */
.section-padding{
    padding: 120px 0;
}
.projects {
    position: relative;
}
.projects:last-child {
    margin-bottom: 0px;
}
.projects figure {
    margin: 0;
    position: relative;
    width: 60.666667%;
    margin-left: auto;
}
.projects.left figure {
    margin-left: 0;
    margin-right: auto;
}
.projects .numbers{

}
/*.projects .numbers-r-side{
    font-size: 114px;
    position: absolute;
    top: 0;
     right: 245px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0 0 0 / 0.5);
    color: transparent;
}
.projects .numbers-l-side{
    font-size: 114px;
    position: absolute;
    top: 0;
     left: 245px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0 0 0 / 0.5);
    color: transparent;
}*/
.projects .caption {
    background: #fff;
    position: absolute;
    left: 0;
    top: 45%;
    width: 50%;
    padding: 5% 4% 5% 4%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.projects.left .caption {
    left: auto;
    top: 45%;
    right: 0;
    background: #fff;
    padding: 5% 4% 5% 4%;
}
.projects .caption.padding-right {
    padding-right: 0%;
}
.projects .caption.padding-left {
    padding-left: 0%;
}
.projects .caption h4,
.projects .caption h4 a {
    font-size: 27px;
    color: #000;
    margin-bottom: 20px;
}
.projects .caption h4 span {
    color: #ed5521;
}

/*============= End All Services area css =============*/


@media (max-width: 1024px){


.projects .numbers-r-side{
    right: 155px;
}
.projects .numbers-l-side{
    left: 32px;
}

    }

/* Style sheet for tablet device*/

@media (max-width: 991px){

.projects .numbers{
    display: none;
}
    }

@media only screen and (min-width: 320px) and (max-width: 735px) {

.mb-90 {
    margin-bottom: 30px !important;
}
.projects figure{
    width: auto;
}
.projects .caption, .projects.left .caption {
    background: #f5f5f5;
}
.projects .caption{
    position: relative;
    top: 0;
    width: auto;
    transform: translate(0, 0);
}

    }
    .image-box img{
        height: 400px;
        /* max-height: 400px; */
        width: 100%;
        object-fit: cover;
    
    }
    /* .image-box ::before{
        content: "";
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    } */